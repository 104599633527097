<template>
  <div class="page">
    <navbar index="3" sub="1" />

    <!-- banner begain -->
    <banner>
      <img src="../../assets/img/banner_pro_2.jpg" class="img-fluid" alt="">
    </banner>
    <!-- banner end -->

    <div class="main-content">

      <!-- 产品介绍 begain -->
        <div class="intro-box">
          <div class="container-fluid intro-content">
            <div class="intro-img">
              <img src="../../assets/img/pro_2_1.jpg" class="img-fluid" alt="">
            </div>
            <div class="intro-txt">
              <div class="intro-title">产品介绍</div>
              <div class="intro-name">可视化综合运维系统</div>
              <div class="intro-desc">可视化智慧管理平台通过物联网技术、人工智能和云计算技术等信息技术实现对服务的实时监测、安全预警告警、设备信息管理、数据可视化等功能。通过对后勤服务的智慧管理，对设备出现的运行异常事件、故障及时做出警示，提供AI故障诊断建议，并根据故障等级提供多方式分级报警第一时间通知相关责任人，实时监控排障进度，切实保障设备运行安全，实现后勤智慧化管理。</div>
            </div>
          </div>  
        </div>
        <!-- 产品介绍 end -->
        
        <!-- 解决方案 begain -->
        <div class="solution-box">
          <section-title title="THE SOLUTION／解决方案 " bar-color="#F0C02C"/>
          <div class="container-fluid solution-content">
            <div class="row solution-item">
              <div class="col-5 offset-1 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>管理可视化</h3>
                  </div>
                  <div class="desc">
                    <p>*界面灵活组态，用户可根据自己的现场环境定义可视化界面。</p>
                    <p>*运行状态展示，自定义需展示的运行数据。</p>
                    <p>*一键查询，对界面内的监控摄像、设备设施一键点击查询。</p>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="solution-img">
                  <img src="../../assets/img/pro_2_2.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1">
                <div class="solution-img">
                  <img src="../../assets/img/pro_2_3.png" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-5 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>链路可视化</h3>
                  </div>
                  <div class="desc">
                    <p>*子系统链路查询，分层展示各子系统链路层级。</p>
                    <p>*全系统链路查询，一键打开全链路所有层级。</p>
                    <p>*链路变更管理，用户可以在现场或后台根据实际需要灵活变更链路状态。</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>网络报修</h3>
                  </div>
                  <div class="desc">
                    <p>*自动工单，员工扫描设备报修后，服务商可直接收到维修通知，无需服务中心转派。</p>
                    <p>*排障支持，选择故障类别后，系统可个性化设定现场排障指引，缩短恢复时间，减少. 上门次数。</p>
                    <p>*进度通知，平台可通过微信、短信、邮件等形式将派单、接单、处理、修复、评价等重要节点主动通知报修人员。</p>
                    <p>*接单抢单，维修任务可以被指定分派给具体的维修人员,也可以分派给一些角色组，角色组内的成员可以根据时间安排和故障内容主动接单。</p>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="solution-img">
                  <img src="../../assets/img/pro_2_4.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1">
                <div class="solution-img">
                  <img src="../../assets/img/pro_2_5.png" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-5 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>巡检盘点</h3>
                  </div>
                  <div class="desc">
                    <p>*周期任务派发，总部根据区域或系统生成周期性巡检盘点计划，每个周期的工作任务会自行下发到相关服务组。</p>
                    <p>*人员定位签到，如启用该功能，运维人员必须到达指定现场后，运维系统将自动识别，识别完成后方可开始执行相关工作。</p>
                    <p>*统计分析报表，平台提供设备周期性巡检/盘点报表，用户可根据相关数据统计和趋势制定相应的策略。</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>运维状态把控</h3>
                  </div>
                  <div class="desc">
                    <p>*工单节点控制，服务台可实时监控各工作进度。</p>
                    <p>*历史数据汇总，根据需要，按不同的维度汇总历史运维记录。</p>
                    <p>*异常原因梳理，各类运维工单异常原因报告。</p>
                    <p>*趋势预测，根据企业历史数据，可对未来数据走向提供趋势预测。</p>
                    <p>*客户定制，可按客户需求定制各类报表。</p>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="solution-img">
                  <img src="../../assets/img/pro_2_6.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1">
                <div class="solution-img">
                  <img src="../../assets/img/pro_2_7.png" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-5 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>资产信息管理</h3>
                  </div>
                  <div class="desc">
                    <p>*扫描查询，现场人员也可通过移动端APP或微信小程序扫描设备标签获取资产管理信息。</p>
                    <p>*属性权限，按不同的工作职能分配资产属性的编辑或查询权限，包括属性字段、文档、运维记录等。</p>
                    <p>*编码生成，确保资产编码的唯一性，可满足用户单位个性化编码规则要求。</p>
                    <p>*全维信息，便捷获取设备的操作手册、操作视频、工作指引文件、维修/巡检/盘点历史记录、全生命周期管理数据，提高普通工作人员的设备使用培训效果，提高业务设备的可用性。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 解决方案 end -->

        <!-- 产品优势 begain -->
        <div class="advant-box">
          <section-title title="PRODUCT ADVANTAGES/产品优势 " bar-color="#F0C02C"/>
          <div class="container-fluid">
            <div class="row">
              <div class="col-10 offset-1 advant-content">
                <div class="advant-item">
                  <img src="../../assets/img/pro_2_8.jpg" class="img-fluid" alt="">
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_2_9.jpg" class="img-fluid" alt="">
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_2_10.jpg" class="img-fluid" alt="">
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_2_11.jpg" class="img-fluid" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 产品优势 end -->
    </div>

    <foot />
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import banner from '@/components/banner'
  import foot from '@/components/footer'
  import sectionTitle from '@/components/sectionTitle'
  export default {
    name: 'Pro2',
    components: {
      navbar,
      banner,
      sectionTitle,
      foot
    }
  }
</script>

<style lang="scss" scoped>
  .intro-box {
    margin: 0 auto;
    padding: 60px 0;
    .intro-content {
      display: flex;
      justify-content: stretch;
      align-items: center;
      padding: 0 15%;
      font-size: 10px;
      font-weight: bold;
      color: #333;
      .intro-img {
        flex: 0 0 30%;
      }
      .intro-txt {
        flex: 1;
        margin-left: 10%;
        .intro-title {
          margin-bottom: .5em;
          font-size: 36px;
        }
        .intro-name {
          margin-bottom: .5em;
          font-size: 32px;
        }
        .intro-desc {
          line-height: 1.8;
          font-size: 24px;
          font-weight: 400;
        }
      }
    }
  }
  .solution-box {
    margin: 0 auto;
    padding: 60px 0;
    .solution-content {
      .solution-item{
        margin-top: 90px;
        .solution-txt {
          display: flex;
          justify-content: space-around;
          .txt-info {
            .title{
              margin-bottom: 30px;
              h3 {
                margin: 0;
                line-height: 1;
                font-size: 30px;
                font-weight: bold;
              }
            }
            .desc {
              p {
                margin: 0;
                line-height: 2;
                font-size: 24px;
                color: #666;
              }
            }
          }
        }
      }
      .solution-img {
        padding-top: 30px;
      }
    }
  }
  .advant-box {
    margin: 0 auto;
    padding: 60px 0;
    .advant-content {
      margin-top: 90px;
      padding-bottom: 90px;
      display: flex;
      justify-content: space-between;
      .advant-item {
        flex: 1;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>